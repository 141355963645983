import { __decorate, __extends, __spreadArrays } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import 'vue-apollo';
import shortid from 'shortid';
import '@/forgeViewerExtensions/HandleSelectionExtension';
var Autodesk = window.Autodesk;
var ForgeViewer = (function (_super) {
    __extends(ForgeViewer, _super);
    function ForgeViewer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.uid = 'forge-viewer-' + shortid.generate();
        _this.activeItem = null;
        _this.viewerDocument = null;
        return _this;
    }
    ForgeViewer.prototype.setState = function (state, immediate) {
        if (state.cutplanes.length == 0) {
            state.cutplanes.push([0, 0, -1, -100000]);
        }
        this.viewer.restoreState(state, this.stateFilter, immediate || false);
    };
    ForgeViewer.prototype.onCamLockChanged = function (val, oldVal) {
        if (this.viewer) {
        }
    };
    ForgeViewer.prototype.onModelUrnChanged = function (val, oldVal) {
        if (this.viewer && val != oldVal)
            this.loadModel();
    };
    ForgeViewer.prototype.onStatePropChanged = function (val, oldVal) {
        if (this.viewer && val != undefined && val != oldVal)
            this.setState(val);
    };
    ForgeViewer.prototype.onViewableIdChanged = function (val, oldVal) {
        var _a, _b;
        if (oldVal !== val && val !== null) {
            var root = (_a = this.viewerDocument) === null || _a === void 0 ? void 0 : _a.getRoot();
            var item = root === null || root === void 0 ? void 0 : root.findByGuid(val);
            var path = (_b = this.viewerDocument) === null || _b === void 0 ? void 0 : _b.getThumbnailPath(val, 400, 400);
            if (item) {
                this.onItemSelected(item);
            }
        }
    };
    ForgeViewer.prototype.mounted = function () {
        var _this = this;
        Autodesk.Viewing.Initializer({
            env: 'AutodeskProduction',
            getAccessToken: function (onTokenReady) {
                return _this.$emit('get-access-token', onTokenReady);
            },
            refreshToken: function (onTokenReady) {
                return _this.$emit('refresh-token', onTokenReady);
            },
            useConsolidation: true
        }, this.initViewer);
    };
    ForgeViewer.prototype.destroyed = function () {
        console.log('shutting down viewer');
        if (this.viewer) {
            this.viewer.unloadExtension('Autodesk.CrossFadeEffects');
            this.viewer.unloadExtension('Autodesk.Viewing.ZoomWindow');
            this.viewer.finish();
            this.viewer = null;
        }
        Autodesk.Viewing.shutdown();
    };
    ForgeViewer.prototype.getViewableItems = function (doc, roles) {
        if (roles === void 0) {
            roles = ['3d', '2d'];
        }
        var rootItem = doc.getRoot();
        var items = [];
        var roleArray = roles ? (Array.isArray(roles) ? roles : [roles]) : [];
        roleArray.forEach(function (role) {
            items = __spreadArrays(items, rootItem.search({ type: 'geometry', role: role }));
        });
        items.forEach(function (item) {
            if (item.data.hasThumbnail) {
                item.data.thumbnail = doc === null || doc === void 0 ? void 0 : doc.getThumbnailPath(item.data.guid, 400, 400);
            }
        });
        return items;
    };
    ForgeViewer.prototype.onItemSelected = function (item) {
        if (item.guid !== this.activeItem.guid) {
            this.viewer.tearDown();
            this.viewer.start();
            var path = this.viewerDocument.getViewablePath(item);
            this.viewer.loadModel(path, {});
            this.activeItem = item.data;
        }
    };
    ForgeViewer.prototype.onViewerStateChanged = function () {
        this.$emit('state-changed', this.viewer.getState(this.stateFilter));
    };
    ForgeViewer.prototype.initViewer = function () {
        var _this = this;
        var viewerDiv = document.getElementById(this.uid);
        if (this.viewer)
            this.viewer.tearDown();
        this.viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv, {
            disabledExtensions: { hyperlink: true }
        });
        this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onViewerStateChanged);
        this.viewer.addEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, this.onViewerStateChanged);
        this.viewer.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.onViewerStateChanged);
        this.viewer.addEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, this.onViewerStateChanged);
        this.viewer.addEventListener(Autodesk.Viewing.HIDE_EVENT, this.onViewerStateChanged);
        this.viewer.addEventListener(Autodesk.Viewing.SHOW_EVENT, this.onViewerStateChanged);
        this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onViewerStateChanged);
        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onViewerStateChanged);
        this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, function (event) {
            return _this.$emit('geometry-loaded', event);
        });
        this.viewer.addEventListener(Autodesk.Viewing.PROGRESS_UPDATE_EVENT, function (event) {
            if (event.state == 1) {
                _this.$emit('progress-update', event);
            }
        });
        this.viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, function (_a) {
            var target = _a.target;
            var settingsTools = document.querySelector('#settingsTools');
            settingsTools.insertAdjacentHTML('beforeend', "<div id=\"markupBtn\" class=\"adsk-control adsk-button inactive\"><div class=\"adsk-button-icon adsk-icon-markup\"></div><div id=\"toolbar-fullscreenTool-tooltip\" class=\"adsk-control-tooltip\" data-i18n=\"Full screen\" tooltiptext=\"Full screen\">markup</div></div>");
            _this.$store.commit('setMarkupExtension');
        });
        this.viewer.setTheme('light-theme');
        this.viewer.addEventListener(Autodesk.Viewing.VIEWER_INITIALIZED, function () {
            var profileSettings = {
                name: 'story-view-mode-settings',
                settings: {
                    ambientShadows: true,
                    groundShadow: false,
                    optimizeNavigation: false,
                    antiAliasing: false,
                    displayLines: false,
                    displayPoints: false,
                    ghostHidden: true,
                    progressiveDisplay: false,
                    swapBlackAndWhite: false,
                    lightPreset: 'Boardwalk'
                },
                extensions: {
                    load: [
                        'Autodesk.Viewing.ZoomWindow',
                        'Autodesk.CrossFadeEffects',
                        'Autodesk.Viewing.MarkupsCore',
                        'Autodesk.FullScreen',
                        'Autodesk.PropertiesManager',
                        'Autodesk.BimWalk'
                    ],
                    unload: [
                        'Autodesk.Explode',
                    ]
                }
            };
            var profile = new Autodesk.Viewing.Profile(profileSettings);
            _this.viewer.setProfile(profile);
            console.log('loaded extensions', _this.viewer.getLoadedExtensions());
        });
        var startedCode = this.viewer.start();
        if (startedCode > 0) {
            console.error('Failed to create a Viewer: WebGL not supported.');
            return;
        }
        if (this.modelURN)
            this.loadModel();
    };
    ForgeViewer.prototype.loadModel = function () {
        var _this = this;
        this.viewer.tearDown();
        this.viewer.start();
        var viewableId = this.viewableId;
        Autodesk.Viewing.Document.load("urn:" + this.modelURN, function (viewerDocument) {
            var viewables = _this.getViewableItems(viewerDocument);
            _this.$emit('viewables-ready', viewables);
            var root = viewerDocument.getRoot();
            var model = !viewableId ? root.getDefaultGeometry() : root.findByGuid(viewableId);
            _this.viewer.loadDocumentNode(viewerDocument, model);
            _this.viewerDocument = viewerDocument;
            _this.activeItem = model.data;
            var path = _this.viewerDocument.getViewablePath(model);
            _this.getMarkupExtension();
            console.log('ForgeViewer -> loadModel -> path', path);
            _this.$emit('model-loaded');
        }, function () { return _this.$emit('model-error', 'Failed fetching Forge manifest'); });
    };
    ForgeViewer.prototype.getMarkupExtension = function () {
        this.viewer.getExtension('Autodesk.Viewing.MarkupsCore');
    };
    __decorate([
        Prop()
    ], ForgeViewer.prototype, "modelURN", void 0);
    __decorate([
        Prop()
    ], ForgeViewer.prototype, "viewableId", void 0);
    __decorate([
        Prop()
    ], ForgeViewer.prototype, "camLock", void 0);
    __decorate([
        Prop()
    ], ForgeViewer.prototype, "state", void 0);
    __decorate([
        Prop()
    ], ForgeViewer.prototype, "stateFilter", void 0);
    __decorate([
        Watch('camLock', { immediate: true })
    ], ForgeViewer.prototype, "onCamLockChanged", null);
    __decorate([
        Watch('modelURN', { immediate: true })
    ], ForgeViewer.prototype, "onModelUrnChanged", null);
    __decorate([
        Watch('state', { immediate: true, deep: true })
    ], ForgeViewer.prototype, "onStatePropChanged", null);
    __decorate([
        Watch('viewableId', { immediate: true })
    ], ForgeViewer.prototype, "onViewableIdChanged", null);
    ForgeViewer = __decorate([
        Component
    ], ForgeViewer);
    return ForgeViewer;
}(Vue));
export default ForgeViewer;
