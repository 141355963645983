import { __extends } from "tslib";
var HandleSelectionExtension = (function (_super) {
    __extends(HandleSelectionExtension, _super);
    function HandleSelectionExtension(viewer, options) {
        var _this = _super.call(this, viewer, options) || this;
        _this._group = null;
        _this._button = null;
        return _this;
    }
    HandleSelectionExtension.prototype.load = function () {
        return true;
    };
    HandleSelectionExtension.prototype.unload = function () {
        if (this._group) {
            this._group.removeControl(this._button);
            if (this._group.getNumberOfControls() === 0) {
                this.viewer.toolbar.removeControl(this._group);
            }
        }
        return true;
    };
    HandleSelectionExtension.prototype.onToolbarCreated = function () {
        var _this = this;
        this._group = this.viewer.toolbar.getControl('allMyAwesomeExtensionsToolbar');
        if (!this._group) {
            this._group = new Autodesk.Viewing.UI.ControlGroup('allMyAwesomeExtensionsToolbar');
            this.viewer.toolbar.addControl(this._group);
        }
        this._button = new Autodesk.Viewing.UI.Button('handleSelectionExtensionButton');
        this._button.onClick = function (ev) {
            _this.handleSelection(ev);
        };
        this._button.setToolTip('Handle Selection Extension');
        this._button.addClass('handleSelectionExtensionIcon');
        this._group.addControl(this._button);
    };
    HandleSelectionExtension.prototype.handleSelection = function (event) {
        var _this = this;
        var selection = this.viewer.getSelection();
        this.viewer.clearSelection();
        if (selection.length > 0) {
            var isolated_1 = [];
            selection.forEach(function (dbId) {
                _this.viewer.getProperties(dbId, function (props) {
                    if (confirm("Isolate " + props.name + " (" + props.externalId + ")?")) {
                        isolated_1.push(dbId);
                        _this.viewer.isolate(isolated_1);
                    }
                });
            });
        }
        else {
            this.viewer.isolate(0);
        }
    };
    return HandleSelectionExtension;
}(Autodesk.Viewing.Extension));
Autodesk.Viewing.theExtensionManager.registerExtension('HandleSelectionExtension', HandleSelectionExtension);
