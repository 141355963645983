var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgeviewer-container" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: this.camLock,
          expression: "this.camLock"
        }
      ],
      staticClass: "viewer-overlay"
    }),
    _c("div", {
      staticClass: "forge-viewer",
      class: this.camLock ? "hide-controls" : "",
      attrs: { id: this.uid }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }